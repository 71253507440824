import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
import FrontPageProductsList from '../../components/FrontPageProductsList';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "ติดต่อ",
      "style": {
        "position": "relative"
      }
    }}>{`ติดต่อ`}<a parentName="h1" {...{
        "href": "#%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD",
        "aria-label": "ติดต่อ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    บริษัทแอนดรีมจำกัด ก่อตั้งเมื่อปี 2008 ทุนจดทะเบียน 1 ล้านบาท
    </LeadParagraph>
    <h3 {...{
      "id": "วิสัยทัศน์-vision",
      "style": {
        "position": "relative"
      }
    }}>{`วิสัยทัศน์ (VISION)`}<a parentName="h3" {...{
        "href": "#%E0%B8%A7%E0%B8%B4%E0%B8%AA%E0%B8%B1%E0%B8%A2%E0%B8%97%E0%B8%B1%E0%B8%A8%E0%B8%99%E0%B9%8C-vision",
        "aria-label": "วิสัยทัศน์ vision permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`สร้างความเป็นเลิศทางด้านเทคโนโลยี่ซอฟแวร์`}</p>
    <h3 {...{
      "id": "พันธกิจ-mission",
      "style": {
        "position": "relative"
      }
    }}>{`พันธกิจ (MISSION)`}<a parentName="h3" {...{
        "href": "#%E0%B8%9E%E0%B8%B1%E0%B8%99%E0%B8%98%E0%B8%81%E0%B8%B4%E0%B8%88-mission",
        "aria-label": "พันธกิจ mission permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`ผลิตและพัฒนาซอฟแวร์ที่ได้มาตรฐานคุณภาพ ด้วยราคาที่ลูกค้าพึงพอใจ`}</p>
    <h3 {...{
      "id": "ที่อยู่",
      "style": {
        "position": "relative"
      }
    }}>{`ที่อยู่`}<a parentName="h3" {...{
        "href": "#%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%AD%E0%B8%A2%E0%B8%B9%E0%B9%88",
        "aria-label": "ที่อยู่ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`เลขที่ 4 ซอย 3 ถนนหลังวัดป่าประดู่ ต.ท่าประดู่ อ.เมือง จ.ระยอง 21000`}</p>
    <h3 {...{
      "id": "email",
      "style": {
        "position": "relative"
      }
    }}>{`Email`}<a parentName="h3" {...{
        "href": "#email",
        "aria-label": "email permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><a parentName="p" {...{
        "href": "mailto:anndream.com@gmail.com"
      }}>{`anndream.com@gmail.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      